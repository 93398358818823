.s-buybox {
    margin-bottom: 100px;

    &__container {
        padding: 80px;
        text-align: center;

        background: rgb(247,204,107);
        background: linear-gradient(90deg, rgba(247,204,107,1) 4%, rgba(221,96,100,1) 43%, rgba(134,88,159,1) 100%);

        border-radius: 25px;

        @include media-breakpoint-down(md) {
            padding: 40px 15px;
        }
    }

    &__headline {
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 10px;
        color: $color-white;

        max-width: 858px;
        margin: 0 auto;

        @include media-breakpoint-down(lg) {
            font-size: 55px;
            line-height: 65px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    &__copy {
        font-family: $font-family-regular;
        color: rgba($color-white, .85);
        font-size: 15px;
        line-height: 21px;
        margin: 35px auto 40px;
        text-align: center;
        max-width: 776px;

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
            font-size: 13px;
            line-height: 18px;
        }
    }
}