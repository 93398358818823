.footer {
    background-color: $color-bg-color-footer;
    padding: 40px 15px;

    @include media-breakpoint-down(sm) {
        padding: 0 15px 30px;
    }

    &__bottom {
        margin-top: 25px;

        @include media-breakpoint-down(md) {
            margin-top: 5px;
        }
    }

    &__contact {
        font-family: $font-family-regular;
        color: $color-white;
        font-size: 13px;
        line-height: 21px;
        text-align: left;

        display: inline-block;

        @include media-breakpoint-down(md) {
            text-align: center;
            margin: 25px auto 0;
            display: table
        }

        &::before {
            content: '';
            background-image: url('../../assets/img/mail-icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
            display: inline-block;

            width: 20px;
            height: 15px;

            margin-right: 8px;
            position: relative;
            top: 3px;
        }

        &:hover {
            color: $color-bg-color-button-dashboard;
            text-decoration: none !important;
        }
    }


    &__link {
        position: relative;
        color: $color-white;
        top: 10px;


        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 10px;
        }

        &__item {
            display: inline-block;
            vertical-align: middle;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.19px;
            color: $color-white;

            &:hover {
                color: $color-bg-color-button-dashboard;
                text-decoration: none;
            }

            &:not(:last-of-type) {
                margin-right: 10px;
            }


            img {
                height: 15px;
            }
        }

        &--social {
            @include media-breakpoint-down(md) {
                margin: 10px 0;
            }
        }
    }

    &__copyright {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.19px;
        color: $color-white;
        margin-top: 15px;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        a {
            font-family: $font-family-bold;
            font-weight: $font-weight-bold;
            color: $color-white;

            &:hover {
                color: $color-bg-color-button-dashboard;
                text-decoration: none;
            }
        }
    }
}