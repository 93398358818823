.s-features {
    padding: 100px 0;

    @include media-breakpoint-down(sm) {
        padding-bottom: 50px;
    }

    &__intro {
        display: flex;
        align-items: center;

        font-family: $font-family-bold-italic;
        font-size: 14px;
        line-height: 21px;
        color: rgba($color-white, .41);

        margin-bottom: 20px;

        &::before {
            content: '';
            width: 100%;
            max-width: 85px;
            height: 3px;
            border-radius: 3px;
            background-color: rgba($color-white, .41);

            display: inline-block;
            margin-right: 25px;
        }
    }

    &__headline {
        font-family: $font-family-black;
        font-size: 50px;
        line-height: 60px;
        color: $color-white;

        max-width: 775px;

        @include media-breakpoint-down(lg) {
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    &__copy {
        font-family: $font-family-regular;
        color: rgba($color-white, .75);
        font-size: 15px;
        line-height: 21px;
        text-align: left;

        @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 18px;
        }
    }

    &__subline {
        font-family: $font-family-regular;
        color: rgba($color-white, .75);
        font-size: 18px;
        line-height: 28px;
        text-align: left;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            line-height: 21px;
        }
    }

    &__list {
        margin: 100px 0;
        list-style: none;

        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 50px;


        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include media-breakpoint-down(sm) {
            margin-top: 50px;
        }

        @include media-breakpoint-down(xs) {
            grid-template-columns: repeat(1, 1fr);
        }

        &-item {
            display: flex;
            align-items: center;

            margin-bottom: 50px;

            @include media-breakpoint-down(xs) {
                margin-bottom: 0;
            }
        }

        &-wrapper {
            margin-left: 30px;
        }

        &-headline {
            font-family: $font-family-bold;
            font-weight: $font-weight-bold;
            font-size: 18px;
            line-height: 28px;
            color: $color-white;
            margin-bottom: 5px;
        }

        &-copy {
            font-family: $font-family-regular;
            color: rgba($color-white, .75);
            font-size: 15px;
            line-height: 21px;
            text-align: left;

            @include media-breakpoint-down(sm) {
                font-size: 13px;
                line-height: 18px;
            }
        }
    }

    &__monitored {
        margin: 50px 0 0 0;
        padding-bottom: 50px;
        list-style: none;

        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 35px;

        border-bottom: 1px solid rgba(#d8d8d8, .26);


        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(2, 1fr);

            grid-gap: 20px;
        }
    }
}