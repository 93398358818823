.s-cms {
    padding: 150px 0 100px;

    @include media-breakpoint-down(sm) {
        padding: 75px 0 50px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-family-black;
        font-size: 50px;
        line-height: 60px;
        color: $color-white;

        @include media-breakpoint-down(lg) {
            font-size: 40px;
            line-height: 50px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 40px;
        }

        margin-bottom: 20px;
    }

    p,
    span,
    div,
    ul {
        font-family: $font-family-regular;
        color: rgba($color-white, .75);
        font-size: 15px;
        line-height: 21px;
        text-align: left;

        @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 18px;
        }

        margin-bottom: 20px;
    }

    ul {
        margin: 0 0 20px 30px;
    }

    &__application {
        width: 100%;
        max-width: 640px;
        margin: 25px auto 0;
        text-align: center !important;

        img {
            display: block;
            margin: 0 auto 50px;
        }

        p,
        h2 {
            text-align: center;
        }

        h1 {
            @include make-responsive(font-size, $h3-font-size);
            line-height: 36px;
            text-align: center;
        }

        .btn {
            display: inline-block;
            margin: 25px auto 45px;
        }

        &-small {
            font-size: 13px !important;
            line-height: 18px !important;
            color: rgba($color-white, .55) !important;
            margin-bottom: 0 !important;
        }
    }
}