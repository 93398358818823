.modal {
    margin: 0 15px;

    .form-field__wrapper {
        margin: 30px auto 10px;
        max-width: 200px;
    }

    &__container  {
        max-width: 500px;
        text-align: center;
        margin: 0 15px;

        img {
            margin: 0 auto 30px;
        }

        .btn {
            margin-top: 30px;

            &--cancel {
                margin-right: 10px;
            }
        }
    }

    &__headline {
        font-family: $font-family-regular;
        color: $color-white;
        font-size: 18px;
        line-height: 28px;
        text-align: center;

        @include media-breakpoint-down(sm) {
            font-size: 15px;
            line-height: 21px;
        }
    }
}