.accordion {
    max-width: 1120px;
    margin: 100px auto;
    list-style: none;

    @include media-breakpoint-down(md) {
       padding: 0;
       margin: 50px auto 100px;
    }

    li {
        background-color: $color-bg-color-accordion;
        border-radius: 12px;
        position: relative;
        margin-bottom: 20px;

        p {
            font-family: $font-family-regular;
            font-size: 14px;
            line-height: 26px;

            display: none;
            padding: 5px 20px 30px;
            color: $color-white;

            a {
                color: $color-white;
                text-decoration: none;
                font-weight: bold;
            }
        }

        span {
            font-family: $font-family-bold;
            font-weight: $font-weight-bold;
            font-size: 18px;
            line-height: 28px;
            color: $color-white;
            display: inline-block;
            width: 100%;
            display: block;
            cursor: pointer;
            user-select: none;
            padding: 15px 30px 15px 20px;

            @include media-breakpoint-down(md) {
                font-size: 16px;
                line-height: 26px;
            }

            &::after {
                @include transition;
                width: 8px;
                height: 8px;
                border-right: 2px solid $color-white;
                border-bottom: 2px solid $color-white;
                position: absolute;
                right: 20px;
                content: " ";
                top: 17px;
                transform: rotate(-45deg);
            }

            &.active::after {
                @include transition;
                transform: rotate(45deg);
            }

            &:hover {
                text-decoration: none;
            }
        }
    }
}