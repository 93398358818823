label {
    font-family: $font-family-regular;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    display: block;
  }

  /* For Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  /* Webkit browsers like Safari and Chrome */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .input-label-user {
    font-family: $font-family-headings;
    font-size: 15px;
    line-height: 22px;
    color: rgba($color-white, 0.51);
    margin-bottom: 5px;
  }

  .select {
    font-family: $font-family-headings;
    box-sizing: border-box;
    width: 100%;
    height: 45px;
    padding: 0 28px 0 15px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    outline: 0;
    border-radius: 4px;
    box-shadow: none;
    background-color: #333333;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Some browsers will not display the caret when using calc, so we put the fallback first */
    background: url("../../assets/img/arrow-down.svg") #333333 no-repeat 98.5% !important; /* !important used for overriding all other customisations */
    background: url("../../assets/img/arrow-down.svg") #333333  no-repeat calc(100% - 10px) !important; /* Better placement regardless of input width */

    color: white;
    cursor: pointer;

    @media all and (-ms-high-contrast: none) {
      padding-right: 0; /* IE10 */

      *::-ms-backdrop,
      body {
        padding-right: 0; /* IE11 */
      }
    }

    &:disabled {
      opacity: 1;
      color: rgba($color-white, .55);
    }
  }

  .form-field {
    margin-bottom: 15px;

    @include media-breakpoint-down(lg) {
      margin-bottom: 10px;
    }
  }

  .input-unstyled {
    appearance: none;
    box-sizing: border-box;
    outline: 0;
    box-shadow: none;
    background: none !important;
    border: 0;
    border-radius: 0;
    font-size: 14px;
    text-align: center;
  }

  .form-field {
    // general wrapper for form-field elements
    &__wrapper {
      margin-bottom: 10px;

      &.has-error {
        label {
          color: #a94442;
        }

        input {
          color: #a94442;
          border-color: #a94442;
        }
      }
    }

    // general label-styling in form-fields
    &__label {
      font-family: $font-family-regular;
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
      margin-bottom: 10px;
      color: #8f9bb3;
      letter-spacing: 0.3px;

      &.has-error {
        color: #a94442;
      }
    }
  }
