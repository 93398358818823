.header {
	transition: all ease-out .2s !important;

	position: fixed;
	z-index: 999;
	width: 100%;
	padding: 20px 0;
	width: 100%;

	background-color: $color-bg-color-header;

	@include media-breakpoint-down(lg) {
		text-align: center;
	}

	@include media-breakpoint-down(md) {
		padding: 15px 0;
	}

	.btn {
		&:not(:last-of-type) {
			margin-right: 20px;
		}
	}
}