/**
* Transitions
*/

$transition-base: all .2s ease-in-out !default;

@mixin transition($duration: .25s, $property: all, $effect: ease-in-out) {
  -webkit-transition-property: $property;
  -webkit-transition-duration: $duration;
  -webkit-transition-timing-function: $effect;

  -moz-transition-property: $property;
  -moz-transition-duration: $duration;
  -moz-transition-timing-function: $effect;

  -ms-transition-property: $property;
  -ms-transition-duration: $duration;
  -ms-transition-timing-function: $effect;

  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $effect;
}
