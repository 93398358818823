/**
 * Colors
 */

$color-white: #fff;
$color-black: #000;
$color-bg-color: #272935;
$color-bg-color-header: #13191c;
$color-bg-color-button-dashboard: #ff61b4;
$color-bg-color-button-purchase: #042ff8;
$color-bg-color-accordion: #3e4152;
$color-bg-color-footer: #1c2328;
$color-bg-modal-container: #1c2328;