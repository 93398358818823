/**
 * Media
 */

/**
 * Make replaced elements `display: block` by default as that's
 * the behavior you want almost all of the time. Inspired by
 * CSS Remedy, with `svg` added as well.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

figure {
  margin: 0;
}

// Constrain images and videos to the parent width

img,
video {
  max-width: 100%;
  height: auto;
}

// If a `width` and/or `height` attribute have been explicitly defined, let’s not make the image fluid.

img[width],
img[height] {
  max-width: none;
}
