/**
 * Make Responsive
 *
 * Responsive helper mixin
 *
 * Usage

 $body-font-size: (
   xs: 10px,
   sm: 14px,
   md: 18px,
   lg: 22px,
   xl: 26px,
   xxl: 30px
 );

 $body-padding-top: (
   xs: 10px,
   sm: 20px,
   md: 30px,
   lg: 40px,
   xl: 50px,
   xxl: 60px
 );

 body {
   @include make-responsive(font-size, $body-font-size);
   @include make-responsive(padding-top, $body-padding-top);
 }

 */

@mixin make-responsive($property, $map) {
  @each $breakpoint, $value in $map {
    @if $breakpoint == xs {
      #{$property}: $value;
    }
    @else {
      @include media-breakpoint-up($breakpoint) {
        #{$property}: $value;
      }
    }
  }
}
