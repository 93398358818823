/**
 * Utilities
 *
 * Based on https://github.com/twbs/bootstrap/blob/master/scss/_utilities.scss
 */

 $use-utilities: true;

 $utilities: () !default;
 $utilities: map-merge(
   (
     // Backgrounds
     "background-color": (
       responsive: true,
       property: background-color,
       class: bg,
     ),
     "background-size": (
       responsive: true,
       property: background-size,
       class: bg,
       values: auto cover contain
     ),
     // Layout
     "display": (
       responsive: true,
       property: display,
       class: no,
       values: (
         hidden: none,
         inline: inline,
         inline-block: inline-block,
         block: block,
         table: table,
         table-row: table-row,
         table-cell: table-cell,
         flex: flex,
         inline-flex: inline-flex
       )
     ),
     "position": (
       responsive: true,
       property: position,
       class: no,
       values: static relative absolute fixed sticky
     ),
     "top": (
       responsive: true,
       property: top,
       values: auto 0
     ),
     "right": (
       responsive: true,
       property: right,
       values: auto 0
     ),
     "bottom": (
       responsive: true,
       property: bottom,
       values: auto 0
     ),
     "left": (
       property: left,
       values: auto 0
     ),
     "width": (
       responsive: true,
       property: width,
       class: w,
       values: (
         0: 0,
         '1\\/2': 50%,
         '1\\/3': 33.333333%,
         '2\\/3': 66.666667%,
         '1\\/4': 25%,
         '2\\/4': 50%,
         '3\\/4': 75%,
         '1\\/5': 20%,
         '2\\/5': 40%,
         '3\\/5': 60%,
         '4\\/5': 80%,
         '1\\/6': 16.666667%,
         '2\\/6': 33.333333%,
         '3\\/6': 50%,
         '4\\/6': 66.666667%,
         '5\\/6': 83.333333%,
         '1\\/12': 8.333333%,
         '2\\/12': 16.666667%,
         '3\\/12': 25%,
         '4\\/12': 33.333333%,
         '5\\/12': 41.666667%,
         '6\\/12': 50%,
         '7\\/12': 58.333333%,
         '8\\/12': 66.666667%,
         '9\\/12': 75%,
         '10\\/12': 83.333333%,
         '11\\/12': 91.666667%,
         'full': 100%,
         'w-screen': 100vw,
         'auto': auto
       )
     ),
     // Typography
     "font-family": (
       responsive: true,
       property: font-family,
       values: (
         base: $font-family-regular
       )
     ),
     "font-style": (
       responsive: true,
       property: font-style,
       class: no,
       values: (
         italic: italic,
         non-italic: normal
       )
     ),
     "list-style-type": (
       responsive: true,
       property: list-style-type,
       class: no,
       values: (
         list-none: none,
         list-disc: disc,
         list-decimal: decimal
       )
     ),
     "list-style-position": (
       responsive: true,
       property: list-style-position,
       class: no,
       values: (
         list-inside: inside,
         list-outside: outside
       )
     ),
     "text-align": (
       responsive: true,
       property: text-align,
       class: text,
       values: left right center justify
     ),
     "text-decoration": (
       responsive: true,
       property: text-decoration,
       class: no,
       values: (
         no-underline: none,
         underline: underline,
         line-through: line-through
       )
     ),
     "text-transform": (
       responsive: true,
       property: text-transform,
       class: no,
       values: lowercase uppercase capitalize
     ),
     // Flexbox
     "align-content": (
       responsive: true,
       property: align-content,
       class: no,
       values: (
         content-start: flex-start,
         content-end: flex-end,
         content-center: center,
         content-between: space-between,
         content-around: space-around,
         content-stretch: stretch,
       )
     ),
     "align-items": (
       responsive: true,
       property: align-items,
       class: no,
       values: (
         items-start: flex-start,
         items-end: flex-end,
         items-center: center,
         items-baseline: baseline,
         items-stretch: stretch,
       )
     ),
     "align-self": (
       responsive: true,
       property: align-self,
       class: no,
       values: (
         self-auto: auto,
         self-start: flex-start,
         self-end: flex-end,
         self-center: center,
         self-baseline: baseline,
         self-stretch: stretch,
       )
     ),
     "flex": (
       responsive: true,
       property: flex,
       values: (inital: 0 1 auto, 1: 1 1 0%, auto: 1 1 auto, none: none)
     ),
     "flex-direction": (
       responsive: true,
       property: flex-direction,
       class: flex,
       values: row column row-reverse column-reverse
     ),
     "flex-grow": (
       responsive: true,
       property: flex-grow,
       class: flex,
       values: (
         grow: 0,
         grow-1: 1,
       )
     ),
     "flex-shrink": (
       responsive: true,
       property: flex-shrink,
       class: flex,
       values: (
         shrink: 0,
         shrink-1: 1,
       )
     ),
     "flex-wrap": (
       responsive: true,
       property: flex-wrap,
       class: flex,
       values: wrap nowrap wrap-reverse
     ),
     "justify-content": (
       responsive: true,
       property: justify-content,
       class: no,
       values: (
         justify-start: flex-start,
         justify-end: flex-end,
         justify-center: center,
         justify-between: space-between,
         justify-around: space-around,
       )
     ),
     "order": (
       responsive: true,
       property: order,
       values: (
         first: -9999,
         none: 0,
         1: 1,
         2: 2,
         3: 3,
         4: 4,
         5: 5,
         last: 9999,
       ),
     ),
     // Spacing - Margin
     "margin": (
       responsive: true,
       property: margin,
       class: m,
       values: $spacers
     ),
     "margin-x": (
       responsive: true,
       property: margin-right margin-left,
       class: mx,
       values: $spacers
     ),
     "margin-y": (
       responsive: true,
       property: margin-top margin-bottom,
       class: my,
       values: $spacers
     ),
     "margin-top": (
       responsive: true,
       property: margin-top,
       class: mt,
       values: $spacers
     ),
     "margin-right": (
       responsive: true,
       property: margin-right,
       class: mr,
       values: $spacers
     ),
     "margin-bottom": (
       responsive: true,
       property: margin-bottom,
       class: mb,
       values: $spacers
     ),
     "margin-left": (
       responsive: true,
       property: margin-left,
       class: ml,
       values: $spacers
     ),
     // Spacing - Negative Margin
     "negative-margin": (
       responsive: true,
       property: margin,
       class: -m,
       values: $negative-spacers
     ),
     "negative-margin-x": (
       responsive: true,
       property: margin-right margin-left,
       class: -mx,
       values: $negative-spacers
     ),
     "negative-margin-y": (
       responsive: true,
       property: margin-top margin-bottom,
       class: -my,
       values: $negative-spacers
     ),
     "negative-margin-top": (
       responsive: true,
       property: margin-top,
       class: -mt,
       values: $negative-spacers
     ),
     "negative-margin-right": (
       responsive: true,
       property: margin-right,
       class: -mr,
       values: $negative-spacers
     ),
     "negative-margin-bottom": (
       responsive: true,
       property: margin-bottom,
       class: -mb,
       values: $negative-spacers
     ),
     "negative-margin-left": (
       responsive: true,
       property: margin-left,
       class: -ml,
       values: $negative-spacers
     ),
     // Spacing - Padding
     "padding": (
       responsive: true,
       property: padding,
       class: p,
       values: $spacers
     ),
     "padding-x": (
       responsive: true,
       property: padding-right padding-left,
       class: px,
       values: $spacers
     ),
     "padding-y": (
       responsive: true,
       property: padding-top padding-bottom,
       class: py,
       values: $spacers
     ),
     "padding-top": (
       responsive: true,
       property: padding-top,
       class: pt,
       values: $spacers
     ),
     "padding-right": (
       responsive: true,
       property: padding-right,
       class: pr,
       values: $spacers
     ),
     "padding-bottom": (
       responsive: true,
       property: padding-bottom,
       class: pb,
       values: $spacers
     ),
     "padding-left": (
       responsive: true,
       property: padding-left,
       class: pl,
       values: $spacers
     ),
   ),
   $utilities
 );

 // Disable utilities that we don't need in order to reduce css file size
 // Uncomment utiltiy to remove that from CSS

 $utilities: map-remove(
   $utilities,
   // "background-color",
   // "background-size",
   // "display",
   // "position",
   // "top",
   // "right",
   // "bottom",
   // "left",
   // "width",
   // "color",
   // "font-family",
   // "font-size",
   // "font-weight",
   // "line-height",
   // "list-style-type",
   // "list-style-position",
   // "text-align",
   // "text-decoration",
   // "text-transform",
   // "align-content",
   // "align-items",
   // "align-self",
   // "flex",
   // "flex-direction",
   // "flex-grow",
   // "flex-shrink",
   // "flex-wrap",
   // "justify-content",
   // "order",
   // "margin",
   // "margin-x",
   // "margin-y",
   // "margin-top",
   // "margin-right",
   // "margin-bottom",
   // "margin-left",
   // "negative-margin",
   // "negative-margin-x",
   // "negative-margin-y",
   // "negative-margin-top",
   // "negative-margin-right",
   // "negative-margin-bottom",
   // "negative-margin-left",
   // "padding",
   // "padding-x",
   // "padding-y",
   // "padding-top",
   // "padding-right",
   // "padding-bottom",
   // "padding-left"
 );

 // Ratio
 $ratios: (
   "16-9": (
     x: 16,
     y: 9
   ),
   "3-4": (
     x: 3,
     y: 4
   ),
   "4-3": (
     x: 4,
     y: 3
   ),
 ) !default;
