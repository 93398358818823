// /**
//  * Buttons
//  */
$btn-font-weight: $font-weight-bold !default;
$btn-font-size: 16px !default;
$btn-font-size-mobile: 14px !default;

$btn-padding-x: 25px !default;
$btn-padding-y: 10px !default;

$line-height-base: 30px !default;


/* Common button styles */
.btn {
    font-family: $font-family-regular;
    display: inline-block;
    padding: $btn-padding-y $btn-padding-x;
    color: $color-white;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    font-size: $btn-font-size;
    font-weight: $btn-font-weight;
    line-height: $line-height-base;
    letter-spacing: .2px;
    cursor: pointer;
    user-select: none;
    text-decoration: none;
    border-radius: 8px;
    // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    outline: 0;
    border: 0 !important;
    background: none;
    position: relative;
    z-index: 1;
    min-width: 140px;

    &:focus {
        outline: none;
    }

    > span {
        vertical-align: middle;
    }

    &:disabled {
        pointer-events: none;
    }

    &:hover {
        text-decoration: none !important;
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }
}


.btn--outlined {
	background: transparent;
	color: $color-white;
	overflow: hidden;
    border: 1px solid $color-white !important;
    padding: 10px $btn-padding-x;

    background: linear-gradient(to bottom, transparent 50%, $color-white 50%);
    background-size: 100% 210%;
    background-position: top bottom;
    transition: all .3s ease-out;

    &:hover {
        background-position: bottom;
        color: $color-bg-color-header;
    }
}

.btn--dashboard {
	color: $color-white;
	overflow: hidden;
    border: 1px solid $color-bg-color-button-dashboard !important;
    padding: 10px $btn-padding-x;

    background: linear-gradient(to bottom, $color-bg-color-button-dashboard 50%, transparent 50%);
    background-size: 100% 210%;
    background-position: top bottom;
    transition: all .3s ease-out;

    &:hover {
        background-position: bottom;
        color: $color-bg-color-button-dashboard;

        span {
            color: $color-bg-color-button-dashboard;
        }
    }

    span {
        position: relative;
        top: -2px;
        font-family: $font-family-regular;
        font-weight: $font-weight-regular;
        margin-left: 5px;
    }
}

.btn--purchase {
	color: $color-white;
	overflow: hidden;
    border: 1px solid $color-bg-color-button-purchase !important;
    padding: 10px $btn-padding-x;

    background: linear-gradient(to bottom, $color-bg-color-button-purchase 50%, transparent 50%);
    background-size: 100% 210%;
    background-position: top bottom;
    transition: all .3s ease-out;

    &:hover {
        background-position: bottom;
        color: $color-white;
    }
}

.btn--ghost {
	background: transparent;
	color: $color-white;
	overflow: hidden;
    border: 0 solid transparent;
    padding: 10px $btn-padding-x;

    background: linear-gradient(to bottom, transparent 50%, $color-white 50%);
    background-size: 100% 210%;
    background-position: top bottom;
    transition: all .3s ease-out;

    &:hover {
        background-position: bottom;
        color: $color-bg-color-header;
        border: 1px solid $color-white !important;
    }
}

.btn--buybox {
	color: #272935;
	overflow: hidden;
    border: 1px solid $color-white !important;
    padding: 10px $btn-padding-x;

    background: linear-gradient(to bottom, $color-white 50%, transparent 50%);
    background-size: 100% 210%;
    background-position: top bottom;
    transition: all .3s ease-out;

    &:hover {
        background-position: bottom;
        color: $color-white;
    }
}

.btn--cancel {
	background: transparent;
	color: #fa628a;
	overflow: hidden;
    border: 1px solid #fa628a !important;
    padding: 10px $btn-padding-x;

    background: linear-gradient(to bottom, transparent 50%, #fa628a 50%);
    background-size: 100% 210%;
    background-position: top bottom;
    transition: all .3s ease-out;

    &:hover {
        background-position: bottom;
        color: $color-white;
    }
}
