/**
 * Typography
 */

// Font Family
$font-family-regular: "Lato Regular", Arial, sans-serif, !default;
$font-family-bold: "Lato Bold", Arial, sans-serif, !default;
$font-family-bold-italic: "Lato Bold Italic", Arial, sans-serif, !default;
$font-family-black: "Lato Black", Arial, sans-serif, !default;
$font-family-headings: $font-family-black;

// Font Weight
$font-weight-regular: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 900 !default;

// Line Height
$line-height-none: 1 !default;
$line-height-base: 24px;

// Headings Responsive Size
$h1-font-size: ( xs: 24px, sm: 26px, md: 28px, lg: 32px, xl: 36px, xxl: 40px );
$h2-font-size: ( xs: 22px, sm: 24px, md: 26px, lg: 28px, xl: 32px, xxl: 36px );
$h3-font-size: ( xs: 20px, sm: 22px, md: 24px, lg: 26px, xl: 28px, xxl: 32px );


$headings-font-family: $font-family-headings !default;
$headings-font-weight: $font-weight-bold !default;
$headings-letter-spacing: normal !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;
$headings-text-transform: inherit !default;