.s-faq {
    padding: 50px 0;

    @include media-breakpoint-down(sm) {
        padding: 0;
    }

    &__intro {
        display: flex;
        align-items: center;

        font-family: $font-family-bold-italic;
        font-size: 14px;
        line-height: 21px;
        color: rgba($color-white, .41);

        margin-bottom: 20px;

        &::before {
            content: '';
            width: 100%;
            max-width: 85px;
            height: 3px;
            border-radius: 3px;
            background-color: rgba($color-white, .41);

            display: inline-block;
            margin-right: 25px;
        }
    }

    &__headline {
        font-family: $font-family-black;
        font-size: 50px;
        line-height: 60px;
        color: $color-white;

        max-width: 775px;

        @include media-breakpoint-down(lg) {
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    &__copy {
        font-family: $font-family-regular;
        color: rgba($color-white, .75);
        font-size: 15px;
        line-height: 21px;
        text-align: left;

        @include media-breakpoint-down(sm) {
            font-size: 13px;
            line-height: 18px;
        }
    }
}