.s-opener {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: $color-bg-color-header;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        text-align: center;
        height: 100%;
        align-items: normal;
        padding-bottom: 70px;
    }

    @include media-breakpoint-down(xs) {
        height: 100%;
    }

    &__imgbox,
    .container {
        position: relative;
        z-index: 999;
    }

    .container {
        z-index: 99;

        @include media-breakpoint-down(md) {
            margin-top: -200px;
        }

        @include media-breakpoint-down(xs) {
            margin-top: 0;
        }
    }

    &__imgbox {
        position: relative;
        width: 100% !important;
        z-index: 0;

        @include media-breakpoint-up(lg) {
            position: absolute;
            margin-right: -200px;
        }

        @include media-breakpoint-down(md) {
            position: relative;
            top: -150px;
        }

        @include media-breakpoint-down(xs) {
            top: -10px;
            max-width: 60%;
        }

        .animated-stage {
            width: 100% !important;

            @include media-breakpoint-up(xxl) {
                max-width: 715px;
                float: right;
            }

            @include media-breakpoint-only(xl) {
                max-width: 600px;
                float: right;
            }

            @include media-breakpoint-only(lg) {
                max-width: 500px;
                float: right;
            }

            img {
                @include media-breakpoint-down(md) {
                    margin: 250px auto 0;
                }

                @include media-breakpoint-down(xs) {
                    margin: 95px auto -40px;
                    max-width: 80%;
                }
            }
        }
    }

    &__headline {
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 70px;
        line-height: 80px;
        margin-bottom: 10px;
        color: $color-white;

        @include media-breakpoint-down(lg) {
            font-size: 55px;
            line-height: 65px;
        }

        @include media-breakpoint-down(md) {
            margin-top: 45px;
        }

        @include media-breakpoint-down(sm) {
            font-size: 30px;
            line-height: 40px;
        }
    }

    &__copy {
        font-family: $font-family-regular;
        color: rgba($color-white, .45);
        font-size: 15px;
        line-height: 21px;
        margin: 35px 0 40px;
        max-width: 894px;

        @include media-breakpoint-down(sm) {
            margin-top: 20px;
            font-size: 13px;
            line-height: 18px;
        }
    }

    &__buybox {
        width: auto;
        text-align: center;
        display: inline-block;
    }

    &__info {
        font-family: $font-family-regular;
        color: rgba($color-white, .63);
        font-size: 13px;
        line-height: 18px;
        margin-top: 13px;
    }

    .btn {
        &:not(:last-of-type) {
            margin-right: 15px;
        }
    }

    .shake-stage {
        animation: shake-stage 12s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
    }

    @-webkit-keyframes shake-stage {
        0%,
        100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        }
        10%,
        30%,
        50%,
        70% {
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
        }
        20%,
        40%,
        60% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
        }
        80% {
        -webkit-transform: translateY(6.4px);
                transform: translateY(6.4px);
        }
        90% {
        -webkit-transform: translateY(-6.4px);
                transform: translateY(-6.4px);
        }
    }
    @keyframes shake-stage {
        0%,
        100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        }
        10%,
        30%,
        50%,
        70% {
        -webkit-transform: translateY(-8px);
                transform: translateY(-8px);
        }
        20%,
        40%,
        60% {
        -webkit-transform: translateY(8px);
                transform: translateY(8px);
        }
        80% {
        -webkit-transform: translateY(6.4px);
                transform: translateY(6.4px);
        }
        90% {
        -webkit-transform: translateY(-6.4px);
                transform: translateY(-6.4px);
        }
    }

}