/**
 * Ratio
 *
 * If "true" is defined, we make target as pseudo :before element
 *
 * Usage:
   @include ratio(16,9);
   @include ratio(4,3);
   @include ratio(4,4, true);
 */

@mixin ratio($x,$y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + '%');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @if $pseudo {
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}
