/**
 * Utility generator
 */

@mixin generate-utility($utility, $infix) {
  $values: map-get($utility, values);

  // If the values are a list or string, convert it into a map
  @if type-of($values) == "string" or type-of(nth($values, 1)) != "list" {
    $values: zip($values, $values);
  }

  @each $key, $value in $values {
    $properties: map-get($utility, property);

    // Multiple properties are possible, for example with vertical or horizontal margins or paddings
    @if type-of($properties) == "string" {
      $properties: append((), $properties);
    }

    // Use custom class if present
    $property-class: map-get($utility, class);
    $property-class: if($property-class, $property-class, nth($properties, 1));

    // Don't prefix if value key is null (eg. with shadow class)
    $property-class-modifier: if($key, "-" + $key, "");

    @if $property-class == no {
      $property-class-modifier: $key;
      $property-class: '';
    }

    .#{$infix + $property-class + $property-class-modifier} {
      @each $property in $properties {
        #{$property}: $value;
      }
    }
  }
}
